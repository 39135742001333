import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { minWidth } from '@emico/styles'
import { H2 } from '@emico/ui'

import theme from '../theme'

const Title = styled(H2, {
  shouldForwardProp: (prop) => !['type'].includes(prop.toString()),
})<{ hasLargeTitle: boolean }>`
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};

  @media ${minWidth('md')} {
    font-size: ${({ hasLargeTitle }) =>
      hasLargeTitle && theme.fontSizes['2xl']};
  }
`

interface Props {
  title: ReactNode | string
  hasLargeTitle?: boolean
}

const SectionTitle = ({ title, hasLargeTitle = false, ...other }: Props) => (
  <Title hasLargeTitle={hasLargeTitle} {...other}>
    {title}
  </Title>
)

export default SectionTitle
