import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import theme from '../theme'

interface Props {
  children: ReactNode
  id?: string
}

const Block = styled.section`
  margin-bottom: ${theme.spacing['2xl']};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const CraftBlock = ({ children, ...other }: Props) => (
  <Block {...other}>{children}</Block>
)

export default CraftBlock
