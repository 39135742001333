import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

import { minWidth } from '@emico/styles'
import { H3 } from '@emico/ui'

import theme from '../theme'
import SectionTitle from './SectionTitle'

const Header = styled.header`
  margin-bottom: ${theme.spacing.md};

  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing.lg};
  }
`

export const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: ${theme.spacing.xxs};

  &:last-child {
    margin-bottom: 0;
  }
`

export const Subtitle = styled(H3)`
  color: ${theme.colors.grayBrown};
  font-size: ${theme.fontSizes.xl};
  text-transform: uppercase;
`

interface Props {
  /**
   * Section header title
   * Should always be passed, except when a custom approach is required,
   * in this case a title should be passed within the children
   */
  title?: ReactNode | string
  /**
   * Subtitle that will be placed underneath the title
   */
  subtitle?: ReactNode | string
  /**
   * Should the section header have a large font-size?
   */
  hasLargeTitle?: boolean
  /**
   * Children that will be added within the section header
   */
  children?: ReactNode
}

const SectionHeader = ({
  title,
  subtitle,
  hasLargeTitle = false,
  children,
  ...other
}: Props) => {
  if (!title && !subtitle && !children) {
    return null
  }

  return (
    <Header {...other}>
      {title && (
        <StyledSectionTitle title={title} hasLargeTitle={hasLargeTitle} />
      )}

      {subtitle && <Subtitle>{subtitle}</Subtitle>}

      {children && children}
    </Header>
  )
}

export default SectionHeader
