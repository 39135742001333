import { ProductCardFragment } from '@emico-hooks/product-card-fragment'
import { ComponentSlider } from '@emico-react/component-slider'
import { Picture } from '@emico-react/image'
import { stripMaybes } from '@emico-utils/graphql-data-utils'
import styled from '@emotion/styled'
import { Trans } from '@lingui/macro'
import React, { ComponentProps, useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'

import { maxWidth, minWidth } from '@emico/styles'
import { ButtonUnstyled, Container, H2 } from '@emico/ui'

import {
  CraftImageWithProductOverviewItemFragment,
  CraftPageBlocksFragmentCraftImageWithProductOverviewEntry,
  CraftImageWithProductOverviewsFragment,
  CraftCategoryProductPageBlocksFragmentCraftCategoryImageWithProductOverviewEntry,
} from '../lib/craftFragments.generated'
import { opacityStates } from '../lib/transitionStates'
import useProductsBySkus from '../lib/useProductsBySkus'
import { useWishlist } from '../lib/useWishlist'
import { ArrowButton, PrevButton } from '../overrides/ComponentSlider'
import theme from '../theme'
import CraftBlock from './CraftBlock'
import CraftImage from './CraftImage'
import Modal from './Modal'
import ProductCardSimple from './ProductCardSimple'
import SectionHeader from './SectionHeader'
import { WishlistIconButton } from './WishlistIconButton'

const Text = styled.p`
  margin: 0;
  max-width: 750px;
`

const Grid = styled('div', {
  shouldForwardProp: (prop) =>
    !['itemCount', 'columnCount'].includes(prop.toString()),
})<{
  itemCount: number
  columnCount: number
}>`
  display: grid;
  grid-template-columns: ${({ itemCount }) =>
    `repeat(${itemCount > 3 ? 3 : itemCount}, 1fr)`};
  grid-gap: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    grid-template-columns: ${({ itemCount, columnCount }) =>
      `repeat(${
        itemCount === 1 ? 2 : itemCount > columnCount ? columnCount : itemCount
      }, 1fr)`};
  }
`

const StyledButtonUnstyled = styled(ButtonUnstyled)`
  @media ${minWidth('lg')} {
    ${theme.animation.zoomNestedImage(1.1, theme.transition.durations.normal)};
  }
`

const ModalGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    grid-gap: ${theme.spacing.xl};
  }

  @media ${minWidth('lg')} {
    grid-template-columns: 2fr 1fr;
    grid-gap: ${theme.spacing['2xl']};
  }
`

const StyledComponentSlider = styled(ComponentSlider)`
  ${ArrowButton} {
    top: 50%;
    right: ${theme.spacing.md};
    transform: translateY(-50%);
  }

  ${PrevButton} {
    left: ${theme.spacing.md};
    right: auto;
  }
`

const ModalItemsMobile = styled.div`
  @media ${minWidth('md')} {
    display: none;
  }
`

const ModalItemsTablet = styled.div`
  @media ${maxWidth('sm')} {
    display: none;
  }

  @media ${minWidth('lg')} {
    display: none;
  }
`

const ModalItemsDesktop = styled.div`
  @media ${maxWidth('md')} {
    display: none;
  }
`

const StyledCraftImage = styled(CraftImage)`
  width: 100%;
`

const StyledPicture = styled(Picture)`
  width: 100%;
  height: auto;
  border-radius: ${theme.borderRadius.base};
`

const Column = styled.div`
  position: relative;
`

const ModalContent = styled('div', {
  shouldForwardProp: (prop) => !['state'].includes(prop.toString()),
})<{ state: TransitionStatus }>`
  position: absolute;
  width: 100%;
  padding-bottom: ${theme.spacing.lg};
  opacity: ${({ state }) => opacityStates[state]};
  transition-property: ${theme.transition.properties.common};
  transition-duration: ${theme.transition.durations.extraSlow};
  transition-timing-function: ${theme.transition.timingFunctions
    .cubicBezierSmooth};

  @media ${minWidth('lg')} {
    padding-bottom: 0;
  }
`

const ModalHeader = styled.header`
  margin-bottom: ${theme.spacing.sm};

  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing.xs};
  }
`

const ModalText = styled.p`
  margin-bottom: ${theme.spacing.lg};

  @media ${minWidth('md')} {
    margin-bottom: ${theme.spacing.xl};
  }

  @media ${minWidth('lg')} {
    margin-bottom: ${theme.spacing['2xl']};
  }
`

const ModalProductOverview = styled.div``

const ModalProductsHeader = styled.header`
  margin-bottom: ${theme.spacing.lg};
`

const ModalProductsTitle = styled(H2)``

const ModalProductsGrid = styled('div', {
  shouldForwardProp: (prop) => !['itemCount'].includes(prop.toString()),
})<{
  itemCount: number
}>`
  display: grid;
  grid-template-columns: ${({ itemCount }) =>
    `repeat(${itemCount === 1 ? 2 : itemCount}, 1fr)`};
  grid-gap: ${theme.spacing.md};

  @media ${minWidth('md')} {
    grid-gap: ${theme.spacing.lg};
  }

  @media ${minWidth('lg')} {
    grid-template-columns: ${({ itemCount }) => `repeat(${itemCount}, 1fr)`};
    grid-gap: ${theme.spacing.md};
  }
`

interface ImageWithProductOverviewItemProps {
  item: Omit<CraftImageWithProductOverviewItemFragment, '__typename'>
  state: TransitionStatus
}

const ImageWithProductOverviewItem = ({
  item,
  state,
}: ImageWithProductOverviewItemProps) => {
  const productSkus = (
    item.products
      ? item.products.map(
          (product) =>
            product?.__typename === 'CraftProductEntry' && product.productSku,
        )
      : []
  ).filter(Boolean) as string[]

  const { data: magentoProducts } = useProductsBySkus(productSkus)

  const { getItemBySku, wishlist } = useWishlist()

  return (
    <ModalContent state={state}>
      {item.itemTitle && (
        <ModalHeader>
          <H2>{item.itemTitle}</H2>
        </ModalHeader>
      )}

      {item.description && <ModalText>{item.description}</ModalText>}

      {productSkus.length !== 0 && magentoProducts.length !== 0 && (
        <ModalProductOverview>
          <ModalProductsHeader>
            <ModalProductsTitle>
              <Trans>Shop the products</Trans>
            </ModalProductsTitle>
          </ModalProductsHeader>

          <ModalProductsGrid itemCount={magentoProducts.length}>
            {magentoProducts.map((product) => {
              if (!product) {
                return null
              }

              const wishlistItem = getItemBySku(product.sku)

              return (
                <ProductCardSimple
                  key={product.name}
                  name={product.name}
                  url={product.urlKey}
                  regularPrice={product.priceRange.minimumPrice.regularPrice}
                  finalPrice={product.priceRange.minimumPrice.finalPrice}
                  reviewRating={product.ratingSummary ?? 0}
                  reviewCount={product.reviewCount ?? 0}
                  percentOff={
                    product.priceRange.minimumPrice.discount?.percentOff
                  }
                  image={{
                    url: product.smallImage?.url ?? '',
                    alt: product.name,
                  }}
                  wishlistIcon={
                    <WishlistIconButton
                      product={product as ProductCardFragment}
                      wishlistId={wishlist?.id ?? ''}
                      wishlistItem={wishlistItem}
                      analyticsContext="imageWithProductOverview"
                      analyticsName="wishlist"
                    />
                  }
                  productLabels={product.productLabels?.filter(stripMaybes)}
                  analyticsContext="imageWithProductOverviewBlock"
                />
              )
            })}
          </ModalProductsGrid>
        </ModalProductOverview>
      )}
    </ModalContent>
  )
}

interface SliderProps
  extends Omit<ComponentProps<typeof ComponentSlider>, 'children'>,
    Pick<ComponentProps<typeof CraftImage>, 'type'> {
  items: Exclude<Props['block']['items'], null>
}

const Slider = ({ items, type, ...other }: SliderProps) => {
  if (!items) {
    return null
  }

  return (
    <ComponentSlider snapAlign="start" slideGap={15} {...other}>
      {items.map((item, index) => {
        const image = item?.image?.[0]

        if (!image) {
          return null
        }

        return (
          <StyledCraftImage
            key={index}
            image={image}
            type={type}
            sizes={theme.imageSizes.fullWidth.sizes}
          />
        )
      })}
    </ComponentSlider>
  )
}

interface Props {
  block:
    | CraftPageBlocksFragmentCraftImageWithProductOverviewEntry
    | CraftCategoryProductPageBlocksFragmentCraftCategoryImageWithProductOverviewEntry
    | CraftImageWithProductOverviewsFragment
  columnCount?: number
  hasContainerPadding?: boolean
}

const CraftImageWithProductOverviewBlock = ({
  block,
  columnCount = 4,
  hasContainerPadding = true,
}: Props) => {
  const nodeRef = useRef(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [initialSlideIndex, setInitialSlideIndex] = useState<number>(0)
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0)

  const handleClick = (index: number) => {
    setInitialSlideIndex(index)
    setShowModal(true)
  }

  const handleSlideChange = (state: number) => {
    setCurrentSlideIndex(state)
  }

  useEffect(() => {
    setCurrentSlideIndex(currentSlideIndex)
  }, [currentSlideIndex])

  if (!block.items) {
    return null
  }

  return (
    <CraftBlock>
      <Container noPadding={!hasContainerPadding}>
        {(block.blockTitle || block.text) && (
          <SectionHeader title={block.blockTitle} hasLargeTitle>
            {block.text && <Text>{block.text}</Text>}
          </SectionHeader>
        )}

        <Grid itemCount={block.items.length} columnCount={columnCount}>
          {block.items.map((item, index) => {
            const image = item?.image?.[0]

            if (!image) {
              return null
            }

            return (
              <StyledButtonUnstyled
                key={index}
                analyticsContext="imageWithProductOverview"
                analyticsName={`item-${index}`}
                onClick={() => handleClick(index)}
              >
                <StyledPicture
                  style={{}}
                  breakpoints={{
                    mobile: {
                      url: image.mobileUrl ?? image.defaultUrl ?? '',
                      width: theme.imageSizes.square.sizes.xs,
                      height: theme.imageSizes.square.sizes.xs,
                    },
                    tablet: {
                      url: image.tabletUrl ?? image.defaultUrl ?? '',
                      width: theme.imageSizes.square.sizes.md,
                      height: theme.imageSizes.square.sizes.md,
                    },
                    desktop: {
                      url: image.desktopUrl ?? image.defaultUrl ?? '',
                      width: theme.imageSizes.square.width,
                      height: theme.imageSizes.square.width,
                    },
                  }}
                  alt={image.title ?? ''}
                  lazy={false}
                />
              </StyledButtonUnstyled>
            )
          })}
        </Grid>
      </Container>

      <Modal show={showModal} close={() => setShowModal(false)}>
        <ModalGrid>
          <ModalItemsMobile>
            <Slider
              items={block.items}
              onSlideChange={handleSlideChange}
              currentSlide={initialSlideIndex}
              slidesToShow={1.25}
              type="mobile"
            />
          </ModalItemsMobile>

          <ModalItemsTablet>
            <Slider
              slidesToShow={1.5}
              items={block.items}
              onSlideChange={handleSlideChange}
              currentSlide={initialSlideIndex}
              type="tablet"
            />
          </ModalItemsTablet>

          <ModalItemsDesktop>
            <StyledComponentSlider
              onSlideChange={handleSlideChange}
              currentSlide={initialSlideIndex}
              slidesToShow={1}
              showArrows={block.items.length > 1}
            >
              {block.items.map((item, index) => {
                const image = item?.image?.[0]

                if (!image) {
                  return null
                }

                return (
                  <StyledCraftImage
                    key={index}
                    image={image}
                    sizes={theme.imageSizes.square.sizes}
                    type="desktop"
                  />
                )
              })}
            </StyledComponentSlider>
          </ModalItemsDesktop>

          <Column>
            {block.items.map((item, index) => {
              if (!item) {
                return null
              }

              return (
                <Transition
                  nodeRef={nodeRef}
                  in={index === currentSlideIndex}
                  timeout={800}
                  unmountOnExit
                  mountOnEnter
                  key={index}
                >
                  {(state: TransitionStatus) => (
                    <ImageWithProductOverviewItem item={item} state={state} />
                  )}
                </Transition>
              )
            })}
          </Column>
        </ModalGrid>
      </Modal>
    </CraftBlock>
  )
}

export default CraftImageWithProductOverviewBlock
